import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import HeaderCase from 'blocks/case/headerCase'
import SectionHalfIntroduction from 'blocks/case/sectionHalfIntroduction'
import SectionHeadingFullWidth from 'blocks/case/sectionHeadingFullWidth'
import Dinamiza from 'components/colorDinamiza'
import SectionTextImageContainer from 'blocks/case/sectionTextImageContainer'
import ParagraphLine from 'components/paragraphLine'
import Image from 'components/image'
import style from 'scss/pages/projects/vega.module.scss'
import SectionDark from 'components/sectionDark'
import SectionTextImageFullWidth from 'blocks/case/sectionTextImageFullWidth'
import SectionSlider from 'blocks/case/sectionSlider'
import FooterCase from 'blocks/case/footerCase'

class Vega extends React.Component {

  render() {

    const { location, data } = this.props

    const imagePath = '/img/projects/vega/'

    const headerInfo = {
      data: "22.08.2019",
      color: "#b23433",
      logoUrl: imagePath + 'logo_vega.svg',
      image: imagePath + 'copertina_vega.jpg',
      title: "MyVEGA Una piattaforma user friendly integrata per un servizio clienti da STAR",
      tags: ["UI-UX DESIGN","BRAND IDENTITY","PROJECT MANAGEMENT","SOFTWARE ARCHITECTURE","SYSTEM INTEGRATION","WEB DEVELOPMENT"]
    }

    const slideImages = ['/img/screenshot/vega1.jpg','/img/screenshot/vega2.jpg','/img/screenshot/vega3.jpg']

    return(
      <div>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Vega" description={'Dinamiza è la Web Agency di Venezia che ti supporta nella creazione e sviluppo dei tuoi progetti di business digitali come piattaforme web, ecommerce.'} />

          <HeaderCase data={headerInfo} />

          <SectionHalfIntroduction>
            <h2>L'innovazione parte dal basso.</h2>
            <p>L'innovazione parte dall'analisi degli utenti. Attraverso questionari, strumenti di monitoraggio del comportamento di chi naviga il sito e mappe concettuali, abbiamo semplificato e migliorato l'esperienza del cliente sulle piattaforme digitali del brand.
              <br/><br/>
              Partendo dall'analisi delle abitudini d'uso dei prodotti e servizi VEGA, sono state studiate funzionalità innovative che posizionano VEGA come brand al passo con i tempi e in grado di anticipare le tendenze e le esigenze del cliente.</p>
          </SectionHalfIntroduction>

          <SectionHeadingFullWidth>
            <h2>Service Design e UX per <Dinamiza>amplificare l'esperienza utente</Dinamiza>.</h2>
          </SectionHeadingFullWidth>

          <SectionTextImageContainer>
            <ParagraphLine>
              <h2 data-title>Web e Mobile Application</h2>
              <p>Abbiamo creato una web app utilizzando la soluzione tecnologica più adatta al progetto. Siamo partiti progettando l'architettura e le funzionalità della app, studiando gli accorgimenti utili a semplificare i processi e il percorso utente per poi procedere allo sviluppo vero e proprio del backend e del frontend.<br />Tra le funzionalità presenti ci sono:
              </p>
              <ul>
                <li>Richiesta carte carburante</li>
                <li>Report rifornimenti</li>
                <li>Gestione contratti</li>
                <li>gestione card e rifornimenti</li>
                <li>fatturazione semplice</li>
                <li>notifiche smart</li>
              </ul>
            </ParagraphLine>
            <div>
              <img src={imagePath+'web_mobile_vega.png'} className={style.page__firstImage} />
            </div>
          </SectionTextImageContainer>

          <SectionTextImageContainer textPosition={'right'}>
            <div className={style.page__thirdContent}>
              <h2>Architecture & Design</h2>
              <p>Il progetto è stato costruito step by step: analisi e progettazione, sviluppo, rilascio e beta test. Il progetto viene monitorato costantemente tramite tracking tool e report per valutare l'implementazione di eventuali migliorie.
              </p>
            </div>
            <div>
              <img src={imagePath+'wireframe_vega.png'} className={style.page__firstImage} />
            </div>
          </SectionTextImageContainer>

          <SectionDark imageSrc={imagePath+'bg_vega.jpg'} imagePosition={'top'}>

            <SectionTextImageFullWidth textPosition={'left'} padding={true} imageFade={false}>
              <img src={imagePath+'system_integration_vega.svg'} className={style.page__firstImageSectionDark} />
              <ParagraphLine>
                <h2 data-title className={`has-color-primary-negative`}>System Integration</h2>
                <p className={`has-color-primary-negative`}>Quando introduciamo nuovi spazi digitali ci occupiamo anche della parte di integrazione con i sistemi di gestione esistenti. Nel caso di MyVEGA è stata studiata una integrazione con il gestionale SAP.</p>
              </ParagraphLine>
            </SectionTextImageFullWidth>

            <SectionTextImageFullWidth textPosition={'left'} padding={false} imageFade={false}>
              <div className={style.page__secondImage}>
                <img src={imagePath+'website_vega.png'} className={style.page__firstImageSectionDark} />
              </div>
              <ParagraphLine>
                <h2 data-title className={`has-color-primary-negative`}>Digital Identity VEGA Carburanti</h2>
                <p className={`has-color-primary-negative`}>La persona è al centro della comunicazione di VEGA. BE A STAR racchiude il concetto di mettere il cliente al primo posto.
                  Questa attenzione si traduce nel fornire assistenza continua e un'esperienza utente curata nei minimi dettagli sia offline che online attraverso lo sviluppo del sito corporate.
                  VEGA valorizza le persone anche tramite iniziative sociali, sportive e culturali che trovano spazio all'interno del sito.<br /><br />
                  <Dinamiza>Fotografia</Dinamiza>: Tresso Basilico & Danese
                </p>
              </ParagraphLine>
            </SectionTextImageFullWidth>

            <SectionSlider slidesData={slideImages}>
              <h2 className={`has-color-primary-negative`}>Risultati</h2>
              <p className={`has-color-primary-negative`}>Il design segue la filosofia aziendale di offrire al cliente "un rifornimento da STAR", portando avanti i valori del brand. Il progetto realizzato non è solo un sito, ma uno spazio user friendly dove mood, soluzioni UX e interattività si fondono per creare il mondo VEGA.</p>
            </SectionSlider>

          </SectionDark>

          <FooterCase prevProject={'ycj'} nextProject={'pathlog'} nextProjectColor={'#009ee0'} />

        </Layout>
      </div>
    )

  }
}

export default Vega
